<template>
    <div>
        <div class="mb-2">
            <a-button @click="$router.back()">
                Regresar
            </a-button>
        </div>
        <div class="flex">
            <div class="w-full">
                <form @submit.prevent="isUpdating ? update(mappedDelivery) : store(mappedDelivery)" @keydown="removeError($event.target.id)">
                    <a-card class="p-2">
                        <h3 class="">{{ isUpdating ? 'Actualizar' : 'Crear' }} entrega final</h3>
                        <div class="flex flex-wrap mx-2">
                            <div class="w-full md:w-1/2 lg:w-1/2">
                                <div class="form-group">
                                    <label for="classroom_id">Aula</label>
                                    <div class="flex flex-wrap w-full">
                                        <a-select
                                            class="flex-grow"
                                            id="classroom_id"
                                            :loading="loadingClassrooms"
                                            :source="classrooms.map(it => ({ label: it.code, value: it.id }))"
                                            :error="error('classroom_id', { attribute: 'aula' })"
                                            v-model="delivery.classroom_id" />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="juries">Jurados</label>
                                    <div class="flex flex-wrap w-full">
                                        <a-select
                                            multiple
                                            class="flex-grow"
                                            id="juries"
                                            :loading="loadingTeachers"
                                            :source="mappedTeachers.map(it => ({ label: `${it.first_name} ${it.last_name}`, value: it.id }))"
                                            :error="error('juries', { attribute: 'profesor' })"
                                            v-model.lazy="juries" />
                                    </div>
                                </div>
                                <div class="form-group flex mt-4">
                                    <a-switch v-model="delivery.teacher_as_jury" class="mr-2"/>
                                    <label for="classroom_id" v-if="currentCourseSectionOnState && currentCourseSectionOnState.teacher">
                                        ¿El profesor {{ `${currentCourseSectionOnState.teacher.first_name} ${currentCourseSectionOnState.teacher.last_name}` }} puede registrar nota?
                                    </label>
                                </div>

                                <div class="form-group my-4">
                                    <label for="summary" class="block">Resumen (opcional)</label>
                                    <textarea id="summary" v-model="delivery.summary" class="block border-2 border-dgray" cols="65"></textarea>
                                </div>
                            </div>
                        </div>

                        <template #footer>
                            <a-button class="mr-2" type="submit" :loading="loadingClassrooms || loadingTeachers" :disabled="loadingClassrooms || loadingTeachers">
                                {{ isUpdating ? 'Actualizar cambios' : 'Guardar cambios' }}
                            </a-button>

                            <a-button outlined :to="{ name: 'schedules' }">
                                Cancelar
                            </a-button>
                        </template>
                    </a-card>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    data: () => ({
        delivery: {
            course_section_id: null,
            teacher_as_jury: false,
        },
        juries: [],
        isUpdating: false
    }),
    computed: {
        ...mapGetters({
            hasError: 'hasError',
            error: 'error'
        }),
        ...mapState({
            currentCourseSectionOnState: state => state.sections.courseSection,
            classrooms: state => state.classrooms.all,
            loadingClassrooms: state => state.classrooms.loading,
            user: state => state.session.user,
            teachers: state => state.teachers.all,
            loadingTeachers: state => state.teachers.loading
        }),
        mappedDelivery() {
            return {
                ...this.delivery,
                juries: this.juries.map(it => ({teacher_id: it}))
            }
        },
        mappedTeachers() {
            return this.teachers.filter(it => it.id !== this.currentCourseSectionOnState?.teacher_id)
        }
    },
    methods: {
        ...mapMutations({
            setNavbarTitle: 'SET_NAVBAR_TITLE',
            setClassrooms: 'classrooms/SET_CLASSHOURS'
        }),
        ...mapActions({
            store: 'finalDeliveries/store',
            removeError: 'removeError',
            fetchCourseSections: 'sections/fetchCourseSections',
            fetchClassrooms: 'classrooms/index',
            fetchTeachers: 'teachers/index',
            fetchSection: 'sections/fetch'
        })
    },
    watch: {
        currentCourseSectionOnState(val) {
            this.delivery.course_section_id = val.id
        }
    },
    mounted() {
        this.fetchSection(this.$route.params.section_id)

        this.fetchCourseSections({
            ...this.$route.params,
            filters: {
                with: 'teacher'
            },
        })

        this.fetchTeachers({
            enabled: true,
            limit: 0
        })
        this.fetchClassrooms({ limit: 0, enabled: true })
    },
    destroyed() {
        this.setClassrooms([])
    }
}
</script>
